import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/shared/layout';
import SEO from '../components/SEO/SEOPage';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import {
  wrapper,
  wrapper1200,
  featuredWrapper,
  noFeaturedWrapper,
  breakpoints,
  ContentStyles,
  ContentStylesExt,
} from '../utils/style';
import Breadcrumbs from '../components/shared/Breadcrumbs';
import Parallax from '../components/shared/Parallax';

const WrapperDesktop = styled('div')`
  ${wrapper1200}
`;

const FeaturedWrapper = styled('div')`
  ${featuredWrapper}
  position: relative;
  max-height: 600px;
  overflow: hidden;
  margin-bottom: 30px;
  h1 {
    position: absolute;
    top: 50%;
    left: 30px;
    margin: -33px 0 0;
    max-width: 50%;
    font-size: 3rem;
    line-height: 1.1;
  }
  .gatsby-image-wrapper {
    border-radius: 24px;
  }
  img {
    border-radius: 24px;
  }
  @media (max-width: ${breakpoints.md}) {
    .gatsby-image-wrapper,
    img {
      border-radius: 10px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    h1 {
      font-size: 1.5rem;
      margin-top: -5px;
      left: 20px;
    }
  }
`;

const NoFeaturedWrapper = styled('div')`
  ${noFeaturedWrapper}
`;

const ContentWrapper = styled('div')`
  ${wrapper}
  ${ContentStyles}
  ${ContentStylesExt}
`;
/*const ContentWrapperWide = styled('div')`
  padding: 40px 0;
  margin-top: 40px;
  border-top: 1px solid ${color.transparentWhite1};
  ${ContentStyles}
  color: ${color.textGrey};
  h2, h3, h4 {
    color: ${color.textGrey};
  }

  @media (max-width: ${breakpoints.lg}) {
    column-count: 1;
  }
`;*/

const PageTemplate = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage;
  const featuredMedia = currentPage.featured_media;
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : currentPage.title + '- ' + process.env.OWNER;
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : '';
  //  const media = data.allWordpressWpMedia.edges;
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;
  const socialImage = currentPage.featured_media?.localFile?.publicURL;

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <Parallax />
      <WrapperDesktop>
        {featuredMedia &&
        featuredMedia.localFile &&
        featuredMedia.localFile.childImageSharp &&
        featuredMedia.localFile.childImageSharp.fluid ? (
          <FeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
            <Img
              fluid={featuredMedia.localFile.childImageSharp.fluid}
              alt={
                currentPage.featured_media.alt_text
                  ? currentPage.featured_media.alt_text
                  : page_title
              }
            />
            <h1 dangerouslySetInnerHTML={{ __html: page_title }} />
          </FeaturedWrapper>
        ) : (
          <NoFeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
            <h1 dangerouslySetInnerHTML={{ __html: page_title }} />
          </NoFeaturedWrapper>
        )}

        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
        </ContentWrapper>
      </WrapperDesktop>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!, $contentImages: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allWordpressWpMedia(filter: { wordpress_id: { in: $contentImages } }) {
      edges {
        node {
          wordpress_id
          alt_text
          source_url
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
              }
            }
          }
        }
      }
    }
  }
`;
